
<template>
<form autocomplete="off" class="allocation-create-form">
  <is-loading v-if="isLoading" :box="true" />
  <div v-if="medicine" class="mb-3">
    Allocate {{medicine.name}} - {{medicine.description}}; Available quantity: <strong> {{medicine.available_quantity}} </strong>
  </div>
  <div v-if="availability && hospital" class="row gx-lg-4 g-3">
    <div  class="col-12">
      <label>Select hospitals</label>
      <div>
        <div  class="mb-3">
          {{ hospital.name }}
          <small>
            <span v-if="hospital.ward" class="mb-0">
            {{hospital.ward.name}}
            </span>
            <span v-if="hospital.lga">,  
            {{hospital.lga.name}}
            </span>
          </small>
        </div>
        <h6> Allocated Quantity - {{ availability.total_allocated_quantity }};
           Available Quantity - {{ availability.available_quantity }};  Pending Quantity - {{ availability.total_pending_quantity }}; Undispatched Quantity - {{ availability.total_undispatched_quantity }}  </h6>
      </div>
    </div> 
    <div class="col-sm-6 col-md-3">
      <label class="form-label" for="inputQuantity">Quantity to each hospital</label>
      <input id="inputQuantity" type="number" class="form-control" v-model="data.quantity"
      :class="{ 'is-invalid': v$.data.quantity.$error }" placeholder="Quantity" name="quantity" />
      <div v-for="(item, index) in v$.data.quantity.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-sm-6 col-md-4">
      <label class="form-label">Assign Dispatcher</label>
      <select class="form-select" v-model="data.dispatcher_id"
        :class="{ 'is-invalid': v$.data.dispatcher_id.$error }"
        placeholder="--select dispatcher--">
        <option disabled :value="null" key="-1">-- select dispatcher--</option>
        <option :value="dispatcher.id" v-for="(dispatcher, index) in dispatchers" 
        :key="index">{{dispatcher.first_name}} {{ dispatcher.last_name}}</option>
      </select>
      <div v-for="(item, index) in v$.data.dispatcher_id.$errors"
        :key="index" class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-md-5">
      <label class="form-label">Description</label>
      <input placeholder="Description" type="text" class="form-control" v-model="data.description" />
    </div> 
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-12">
      <div class="my-3"><h5>Total Allocation: {{totalAllocation}} </h5></div>
      <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" 
        @click.prevent="confirmCreate()"
            type="button"> Submit </b-button>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { required, email, maxValue, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    IsLoading,
  },
  props:{
    request:{
      type: Object,
      required: true 
    },
  },
  data() {
    return {
      isLoading: true,
      data: {
        medicine_id: this.request.medicine_id,
        medicine_request_id: this.request.id,
        quantity: 0,
        description: "",
        dispatcher_id: null,
        hospitals: [ this.request.hospital_id ],
      },
      mirrorData: null,
      hospitalList:[]
    }
  },
  validations() {
    return {
      data:{
        quantity: {
          required: helpers.withMessage("Quantity is required", required),
          maxValue: maxValue(this.maxQuantity)
        },
        dispatcher_id: {
          required: helpers.withMessage("The Dispatcher is required", required)
        },
      }
    }
  },
  computed:{
    hospital(){
      return this.request.hospital
    },
    medicine(){
      return this.request.medicine
    },
    availability(){
      return this.request.availability
    },
    dispatchers(){
      return this.$store.state.userList.dispatchers
    },
    totalAllocation(){
      const totalAllocation = parseInt(this.data.quantity) * this.data.hospitals.length;
      if (isNaN(totalAllocation)) {
        return 0;
      }
      return totalAllocation;
    },
    maxQuantity(){
      return this.data.hospitals.length 
            ? parseInt(this.medicine.available_quantity / this.data.hospitals.length)
            : this.medicine.available_quantity
    }
  },
  methods: {
    confirmCreate(){
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        this.confirmAction({
          text: `You are about to allocate <strong> ${this.totalAllocation} </strong> quantities of ${this.medicine.name}.
             After this action, a total of <strong>${ this.medicine.available_quantity - this.totalAllocation }</strong> will be left.`,
        }).then((result) => {
          if (result.value) { this.create(); }
        });
      })
    },
    create() {
      let formData = new FormData();
      formData.append('data', JSON.stringify(this.data))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post('/allocations/create', formData)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue", false)
        if(response.data.success){
          this.$emit('addItem', response.data.data)
          this.$store.commit("allocation/ADD_ITEM", response.data.data)
          this.resetForm();
        }
      })
    },
    removeTag(itemId) {
      this.data.hospitals = this.data.hospitals.filter(id =>  id !== itemId);
    },
    getOptionData(){
      this.isLoading = true;
      this.$store.dispatch('userList/fetchDispatchers')
      .then((response) => {
        this.isLoading = false
      })
    },
    resetForm() {
      this.data = {...this.mirrorData}
      this.v$.$reset()
      this.$emit('closeMe')
    },
  },
  mounted(){
    this.getOptionData()
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
  }
}
</script>

<style>
.allocation-create-form .multiselect-dropdown{
  max-height:30rem
}
</style>